import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CandleChart from '../Common/Charts/CandleChart';
import {getStock, getInfo, getHistorical, getSurprise, getAllEarnings} from "../../api/api";
import LoadingBar from "../Common/loadingBar/LoadingBar";


function ChartPage() {
    const { ticker } = useParams();
    const [data, setData] = useState(null);
    const [info, setInfo] = useState(null);
    const [historical, setHistorical] = useState(null);
    const [surprise, setSurprise] = useState(null);
    
    useEffect(() => {
        getAllEarnings(ticker).then((res) => {
            console.log(res);
            setInfo(res);
        })
    }, [ticker]);


    if(data === null){
        return (<LoadingBar />);
    } else {
        return (
            <>
                <CandleChart data={data} />
            </>
        );
    }
}

export default ChartPage;