import React, { useState } from 'react';
import Chart from 'react-apexcharts';

function CandleChart(props) {
    const [charOptions, setChartOptions] = useState();
    const [chartData, setChartData] = useState();

    const options = {
        options: {
            chart: {
                type: 'candlestick'
            },
            xaxis: {
                type: 'datetime'
            },
            yaxis: {
                tooltip: {
                    enabled: true
                }
            },
            theme: {
                mode: 'dark',
                palette: 'palette1',

            }

        }
    }


    return(
        <>
            <Chart type='candlestick' series={props.data} options={options} height={350}/>
        </>
    );


}

export default CandleChart;