import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// https://stonks.gennaro.me/api/stock/get?tickerID=tsl
export const getStock = (ticker) => {
    return axios.get('/stock/get', {
        params: {
            tickerID: ticker
        }
    }).then((res) => {
        console.log(res);
        return [];
    }).catch((err) => {
        console.log(err);
        return [];
    });
}

// /symbol/{ticker}/info
export const getInfo = (ticker) => {
    return axios.get(`/symbol/${ticker}/info`)
        .then((res) => {
            console.log(res);
            return res;
    }).catch((err) => {
        console.log(err);
        return [];
    });
}

// /symbol/{ticker}/historical
export const getHistorical = (ticker, toDate, fromDate) => {
    return axios.get(`/symbol/${ticker}/historical`, {
        params: {
            toDate: toDate,
            fromDate: fromDate
        }
    }).then((res) => {
        console.log(res);
        return res;
    }).catch((err) => {
        console.log(err);
        return [];
    });
}

// /symbol/{ticker}/earnings/surprise
export const getSurprise = (ticker) => {
    return axios.get(`/symbol/${ticker}/earnings/surprise`)
        .then((res) => {
            console.log(res);
            return res;
    }).catch((err) => {
        console.log(err);
        return [];
    });
}

// /symbol/{ticker}/earnings/all
export const getAllEarnings = (ticker) => {
    return axios.get(`/symbol/${ticker}/earnings/all`)
        .then((res) => {
            console.log(res);
            return res;
        }).catch((err) => {
            console.log(err);
            return [];
        });
}