import React from 'react';
import './loadingBar.css';

function LoadingBar() {
    return (
        <div className='d-flex justify-content-center m-5'>
            <div className='loader'/>
        </div>
    );
}

export default LoadingBar;