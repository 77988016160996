import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import NavbarComponent from './components/Common/NavBar/NavbarComponent';
import HomePage from './components/HomePage/HomePage';
import ChartPage from "./components/ChartPage/ChartPage";

function App() {
  return (
      <>
          <BrowserRouter>
              <NavbarComponent/>
              <Routes>
                  <Route exact path='/' element={<HomePage/>}/>
                  <Route path='/stock/:ticker' element={<ChartPage/>}/>
              </Routes>
          </BrowserRouter>
      </>
  );
}

export default App;
