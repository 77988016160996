import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function NavbarComponent() {
    const navigate = useNavigate();
    const [ticker, setTicker] = useState('');

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/stock/${ticker}`);
    }

    return (
        <>
            <Navbar bg='dark' variant='dark' expand='lg'>
                <Navbar.Brand href='/'>Stock Tracker</Navbar.Brand>
                <Navbar.Toggle aria-expanded='false' aria-label='Toggle navigation' type='button'/>
                <Navbar.Collapse>
                    <Nav className='mr-auto'>
                        <Nav.Item>
                            <Nav.Link href='/'>Home</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Form className='form-inline my-2 my-lg-0'>
                        <Form.Control
                            onChange={(e) => setTicker(e.target.value)}
                            value={ticker}
                            type='text' className='mr-sm-2'
                            placeholder='TSLA'
                            id='ticker'
                        />
                        <Button onClick={handleClick} variant='secondary' className='my-2 my-sm-0'>Search</Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default NavbarComponent;