import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

function HomePage() {
    const navigate = useNavigate();
    const [ticker, setTicker] = useState('');

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/stock/${ticker}`);
    }
    return (
        <>
            <Container className='d-flex h-100 flex-grow-1 justify-content-center align-items-center'>
                <Card className='m-5 flex-grow-1' bg='light'>
                    <Card.Header className='h2 text-center'>Stock Tracker</Card.Header>
                    <Card.Body>
                        <Form>
                            <FormGroup>
                                <InputGroup className='mb-3'>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <FormControl
                                        aria-label='Stock Ticker'
                                        size='lg'
                                        value={ticker}
                                        onChange={(e) => setTicker(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <Button
                                onClick={handleClick}
                                variant='outline-secondary'
                                className='w-100'
                                size='lg'
                            >Search</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default HomePage;

